import React, { useContext, useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

// import AOS from 'aos';
// import 'aos/dist/aos.css';

//sections
import WorkCaseStudySection from './sections/Work/WorkCaseStudySection';
import BlogSection from './sections/BlogSection';

const Work = ({data}) => {
  const mainData = data.allWpPage.nodes[0].workACF;

  // useEffect(() => {
  //   AOS.init();
  // },[])
  return (
    <Layout>
      <Seo title="Work" />
      <div className="page-wrapper" id="work-page-wrapper">
        <WorkCaseStudySection 
          mainTitle={parse(mainData.workSectionTitle)}
          mainDescription={parse(mainData.workSectionDescription)}
          list={mainData.workSectionList}
        />
        <BlogSection />
      </div>
    </Layout>
  )
}

export default Work;

export const indexQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "work"}}) {
    nodes {
      workACF {
        workSectionTitle
        workSectionDescription
        workSectionList {
          title
          description
          mainDescription
          thumbnail1x {
            altText
            sourceUrl
            width
          }
          thumbnail2x {
            altText
            sourceUrl
            width
          }
          thumbnailAnimation
          url
          tag
        }
      }
    }
  }
}`

